import React from "react"
import PropTypes from "prop-types"
import styled from "styled-components"
import { StickyContainer, Sticky } from "../../../components/sticky.component"
import SmoothAnchor from "../../navigation/smoothAnchor.component"
import { colors, useBreakpoint } from "../../structure/theme"
import { ButtonLink } from "../../structure/theme/button.component"

const ASIDE_WIDTH = { width: 358, paddingLeft: 10 }
const PADDING_TOP = 84 // This is a trick; bring the sidebar down to the level of the first title Wysiwyg
const PADDING_TOP_STICKY = 20

const Aside = styled.aside`
  display: block;
  padding-top: ${props => (props.isSticky ? PADDING_TOP_STICKY : PADDING_TOP)}px;
`

const List = styled.ul`
  padding: 0;
`

const ListItem = styled.li`
  list-style: none;
  padding: 20px 19px;
  border: 1px solid ${colors(`grey.g400`)};
  border-top-width: 0;

  &:first-of-type {
    border-top-width: 1px;
  }
`

const Anchor = styled.a`
  text-decoration: none;
  color: inherit;
`

const CtaWrapper = styled.div`
  max-width: 290px;
  margin: 20px auto 0;
`

const CtaButton = styled(ButtonLink).attrs({
  ghost: true,
  major: true,
})`
  text-transform: none;
  margin-bottom: 14px;
`

export default function Sidebar ({ coBrand, homeSubtitles, homeCoBrandCampaigns }) {
  const breakpoint = useBreakpoint()

  if (breakpoint === `small` || breakpoint === `medium`) {
    return null
  }

  return (
    <StickyContainer style={ASIDE_WIDTH}>
      <Sticky topOffset={PADDING_TOP - PADDING_TOP_STICKY}>
        {({ style, isSticky }) => (
          <Aside style={style} isSticky={isSticky}>
            <List>
              {homeSubtitles.map(subTitle => (
                <ListItem key={subTitle.id}>
                  <SmoothAnchor href={`#${subTitle.id}`} Component={Anchor} data-testid="anchor_sidebar">
                    {subTitle.text}
                  </SmoothAnchor>
                </ListItem>
              ))}
              {homeCoBrandCampaigns.map(homeCoBrandCampaign => (
                <ListItem key={homeCoBrandCampaign.anchor}>
                  <SmoothAnchor data-testid="anchor_sidebar" href={`#${homeCoBrandCampaign.anchor}`} Component={Anchor}>
                    {homeCoBrandCampaign.coBrandCampaign.name}
                  </SmoothAnchor>
                </ListItem>
              ))}
            </List>
            {homeCoBrandCampaigns.length > 0 && (
              <CtaWrapper>
                {homeCoBrandCampaigns.map(homeCoBrandCampaign => (
                  <CtaButton
                    data-testid="cta_sidebar"
                    key={homeCoBrandCampaign.coBrandCampaign.id}
                    route="coBrandLpm"
                    params={{ coBrand, slug: homeCoBrandCampaign.coBrandCampaign.slug }}
                  >
                    {homeCoBrandCampaign.title}
                  </CtaButton>
                ))}
              </CtaWrapper>
            )}
          </Aside>
        )}
      </Sticky>
    </StickyContainer>
  )
}

Sidebar.propTypes = {
  coBrand: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  homeSubtitles: PropTypes.array.isRequired,
  homeCoBrandCampaigns: PropTypes.array.isRequired,
}
