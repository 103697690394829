import React from "react"
import PropTypes from "prop-types"
import { Button } from "../structure/theme/button.component"
import { usePageContext } from "./pageContext.provider"
import _noop from "lodash/noop"
import { scrollTop } from "./navigation.utils"

export default function SmoothAnchor ({ children, href, Component = Button, onClick = _noop, ariaLabel = `` }) {
  const { preventSticky = false } = usePageContext()
  function internalClick (e) {
    e.preventDefault()

    const target = document.getElementById(href.slice(1))
    if (!target) {
      return
    }
    let offset = 20
    if (!preventSticky) {
      const header = document.querySelectorAll(`header`)[0] || null
      if (header) {
        offset += header.getBoundingClientRect().height
      }
    }
    const bodyRect = document.body.getBoundingClientRect().top
    const elementRect = target.getBoundingClientRect().top
    const elementPosition = elementRect - bodyRect
    const offsetPosition = elementPosition - offset

    scrollTop(offsetPosition)
    onClick()
  }

  return (
    <Component
      href={href}
      onClick={internalClick}
      aria-label={ariaLabel}
      data-testid={arguments[0][`data-testid`] || undefined}
    >
      {children}
    </Component>
  )
}

SmoothAnchor.propTypes = {
  children: PropTypes.node.isRequired,
  href: PropTypes.string.isRequired,
  Component: PropTypes.object,
  onClick: PropTypes.func,
  ariaLabel: PropTypes.string,
}
