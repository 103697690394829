import React, { useMemo, useState } from "react"
import styled from "styled-components"
import ThemeProvider from "../../structure/theme.provider"
import { graphql } from "gatsby"
import PropTypes from "prop-types"
import breakpoint from "styled-components-breakpoint"
import Description from "./description.component"
import Wysiwyg from "../../../components/wysiwyg.component"
import { colors, fontSizes } from "../../structure/theme"
import Sidebar from "../theme/sidebar.component"
import { useProductImpressionTracking, useViewCoBrandPageTracking } from "../../thirdParties/gtm/tagManager"
import HomeCta from "./homeCta.component"
import useNavigation from "../../navigation/useNavigation"
import ProductCard from "../../product/productFinder/productCard.component"
import { BevelBanner } from "../../../components/banner/bevelBanner.component"
import { BannerTitle } from "../../../components/banner/bannerTitle.component"
import { BannerContent } from "../../../components/banner/bannerContent.component"
import Section from "../../../components/section.component"

const Container = styled.div`
  display: flex;
`

const Titre1 = styled.h2`
  color: ${colors(`primary`)};
  font-size: ${fontSizes(`x_large`)};
  font-weight: 900;
  margin-top: 40px;
  margin-bottom: 24px;
  width: 100%;
`

const Body = styled.div`
  width: 100%;

  ${breakpoint(`medium`)`
    width: calc(100% - 300px);
    padding-right: 10px;
  `}

  ${Titre1} {
    &:first-of-type {
      margin-top: 0;
    }
  }
`

const Pnomargin = styled.p`
  margin: 0;
`

const ProductCardList = styled.div`
  padding: 0 15px;

  ${breakpoint(`medium`)`
     padding: 0 0;
  `}
`

const replace = (type, attributes) => {
  switch (type) {
    case `h2`: {
      return {
        component: Titre1,
      }
    }
    case `p`: {
      return {
        component: Pnomargin,
      }
    }
  }
}

export default function HomePage ({ data: { coBrand } }) {
  const {
    coBrandHome: { description, body, subtitles, coBrandCampaigns, bannerTitle, bannerText, bannerBgColor },
  } = coBrand

  const jsonBody = useMemo(() => JSON.parse(body), [body])
  const { resolveRoute } = useNavigation()
  const [homeCoBrandCampaignsMap] = useState(
    coBrandCampaigns.map(coBrandCampaign => {
      const products = []

      for (const { landingPage } of coBrandCampaign.coBrandCampaign.coBrandCampaignLandingPages) {
        const lpsProduct = landingPage.landingPageProducts[0]

        products.push({
          ...lpsProduct,
          overrideUrl: resolveRoute(`coBrandLps`, { coBrand, slug: landingPage.slug }),
          impactProduct: {
            ...lpsProduct.impactProduct,
            variants: landingPage.coveritsVariant.slice(0, 1),
          },
        })
      }

      return {
        title: coBrandCampaign.coBrandCampaign.name,
        anchor: coBrandCampaign.anchor,
        products,
      }
    }),
  )

  const [allProducts] = useState(
    homeCoBrandCampaignsMap
      .map(homeCoBrandCampaign => homeCoBrandCampaign.products)
      .reduce((acc, val) => acc.concat(val), []),
  )

  useViewCoBrandPageTracking(`Parcours cobrandés`, `Accueil parcours cobrandes`)
  useProductImpressionTracking(allProducts, _coBrand => `Accueil cobrand - ${_coBrand.brandName || ``}`)

  let offsetPosition = 0

  return (
    <>
      <BevelBanner backgroundColor={bannerBgColor}>
        <Section>
          <BannerContent>
            <BannerTitle dangerouslySetInnerHTML={{ __html: bannerTitle }} />
            <div dangerouslySetInnerHTML={{ __html: bannerText }} />
            <HomeCta coBrand={coBrand} coBrandCampaigns={coBrandCampaigns} />
          </BannerContent>
        </Section>
      </BevelBanner>
      <Section>
        <Description homeDescription={description} />
      </Section>
      <Section>
        <Container>
          <Body>
            <Wysiwyg json={jsonBody} replace={replace} fields={coBrand} />
            <ThemeProvider useCobrandTheme={false}>
              {homeCoBrandCampaignsMap.map(homeCoBrandCampaign => {
                offsetPosition += homeCoBrandCampaign.products.length || 0

                return (
                  <React.Fragment key={homeCoBrandCampaign.anchor}>
                    <Titre1 id={homeCoBrandCampaign.anchor}>{homeCoBrandCampaign.title}</Titre1>
                    <ProductCardList data-testid="product_finder_top_products">
                      {homeCoBrandCampaign.products.map((product, index) => {
                        return (
                          <ProductCard
                            data-testid={`${product.slug
                              .split(`/`)
                              .slice(-2, -1)[0]
                              .replace(/-/g, `_`)}_product_card`}
                            key={product.id}
                            position={index + 1 + offsetPosition - homeCoBrandCampaign.products.length}
                            product={product}
                          />
                        )
                      })}
                    </ProductCardList>
                  </React.Fragment>
                )
              })}
            </ThemeProvider>
          </Body>
          <Sidebar coBrand={coBrand} homeSubtitles={subtitles} homeCoBrandCampaigns={coBrandCampaigns} />
        </Container>
      </Section>
    </>
  )
}

HomePage.propTypes = {
  data: PropTypes.shape({
    coBrand: PropTypes.shape({
      brandName: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
      coBrandHome: PropTypes.shape({
        bannerTitle: PropTypes.string.isRequired,
        bannerText: PropTypes.string.isRequired,
        description: PropTypes.string.isRequired,
        body: PropTypes.string.isRequired,
        subtitles: PropTypes.array.isRequired,
        bodyImages: PropTypes.array.isRequired,
        coBrandCampaigns: PropTypes.array.isRequired,
        bannerBgColor: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
}

export const pageQuery = graphql`
  query CoBrandById($id: String!) {
    coBrand(id: { eq: $id }) {
      brandName
      slug
      coBrandHome {
        bannerTitle
        bannerText
        bannerBgColor
        bodyImages {
          id
          alt
          title
          file {
            childImageSharp {
              fluid(maxWidth: 760) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        description
        body
        subtitles {
          id
          text
        }
        coBrandCampaigns {
          title
          anchor
          coBrandCampaign {
            id
            slug
            name
            coBrandCampaignLandingPages {
              landingPage {
                name
                slug
                id
                coveritsVariant {
                  id
                  offerCode
                  offerPrice
                  standardPrice
                  areaCode
                  campaignEndDate
                }
                landingPageProducts {
                  ...productPageAndSimpleCampaign
                }
              }
            }
          }
        }
      }
    }
  }
`
