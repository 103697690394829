import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import breakpoint from "styled-components-breakpoint"
import { ButtonLink } from "../../structure/theme/button.component"
import { modules } from "../../structure/theme"

const CtaWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${breakpoint(`medium`)`
    flex-direction: row;
  `}
`

const Link = styled(ButtonLink).attrs({
  textColor: `white`,
  backgroundColor: modules(`navigation.breadcrumb.backgroundColor`),
  major: true,
})`
  margin: 20px 5px 0 5px;
  white-space: nowrap;
  text-transform: none;

  ${breakpoint(`medium`)`
    margin-bottom: 0;
  `}
`

export default function HomeCta ({ coBrand, coBrandCampaigns }) {
  return (
    <CtaWrapper>
      {coBrandCampaigns.map(homeCoBrandCampaign => (
        <Link
          data-testid="cta_banner"
          key={homeCoBrandCampaign.coBrandCampaign.id}
          route="coBrandLpm"
          params={{ coBrand, slug: homeCoBrandCampaign.coBrandCampaign.slug }}
        >
          {homeCoBrandCampaign.title}
        </Link>
      ))}
    </CtaWrapper>
  )
}

HomeCta.propTypes = {
  coBrand: PropTypes.shape({
    slug: PropTypes.string.isRequired,
  }).isRequired,
  coBrandCampaigns: PropTypes.array.isRequired,
}
