import React from "react"
import styled from "styled-components"
import PropTypes from "prop-types"
import breakpoint from "styled-components-breakpoint"
import { fontSizes } from "../../structure/theme"

const StyledDescription = styled.div`
  padding: 25px;
  text-align: center;

  font-size: ${fontSizes(`large`)};

  ${breakpoint(`small`)`
    padding: 35px 50px;
  `};

  p {
    margin: 0;
  }
`

export default function Description ({ homeDescription }) {
  return <StyledDescription dangerouslySetInnerHTML={{ __html: homeDescription }} />
}

Description.propTypes = {
  homeDescription: PropTypes.string.isRequired,
}
