import styled from "styled-components"

export const BannerContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 40px auto;

  p {
    margin: 0;
  }
`
