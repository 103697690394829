import styled from "styled-components"
import breakpoint from "styled-components-breakpoint"
import { fontSizes } from "../../modules/structure/theme"

export const BannerTitle = styled.h1`
  font-size: ${fontSizes(`xx_large`)};
  font-weight: 900;
  margin-top: 15px;
  margin-bottom: 15px;

  ${breakpoint(`small`)`
    font-size: ${fontSizes(`xxx_large`)};
  `};
`
